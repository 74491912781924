import { css } from "@emotion/react";
import theme from "./theme";

const GlobalStyles = css`
  html {
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    font-family: ${theme.typography.fontFamily};
    font-size: ${theme.typography.htmlFontSize}px;
  }
  body {
    height: 100%;
  }
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    margin: 0;
    padding: 0;
  }
  img {
    max-width: none;
    height: auto;
  }
  a {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  .hidden {
    display: none;
  }
  .invisible {
    visibility: hidden;
  }
`;

export {
  GlobalStyles
};