import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../Card/Card';
import Loader from '../Loader/Loader';
import shuffle from '../../utils/shuffle';
import { useFlashcardsData } from '../../contexts/DataContext';

const cardsIterator = function* (c) {
  for (let i = 0; i < c.length; i++) {
    yield c[i];
  }
}

let cardsProvider;

const Category = () => {
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const [category, setCategory] = useState(null);
  const [cards, setCards] = useState([]);
  const [card, setCard] = useState(null);
  const {getCategory, getFlashcards} = useFlashcardsData();

  useEffect(() => {
    async function loadData() {
      setLoading(true);

      const category = getCategory(params.categoryId);

      if (!category) {
        setLoading(false);
        
        return;
      }

      // const cardsSnapshot = await getDocs(query(
      //   collection(firestore, "cards"),
      //   where("categoryId", "==", category.id),
      //   where("disabled", "==", false),
      //   orderBy("order"),
      // ));

      const cards = getFlashcards(params.categoryId);
      cardsProvider = cardsIterator(shuffle([...cards]));

      setCategory(category);
      setCards(cards);
      setCard(cardsProvider.next().value);

      setLoading(false);
    }

    loadData()
      .catch((e) => {
        setLoading(false);
      });
  }, [params.categoryId, getCategory, getFlashcards]);

  const onCheckHandler = () => {
    setOpened(opened => !opened)
  }

  const onContinueHandler = () => {
    setOpened(opened => !opened);
    setCard(cardsProvider.next().value);
  }

  const onResetHandler = () => {
    cardsProvider = cardsIterator(shuffle([...cards]));
    setCard(cardsProvider.next().value);
  }

  return (
    loading ? <Loader covered /> :
      <>
        {
          category 
            ? cards.length
              ? 
                <Card 
                  card={card} 
                  opened={opened} 
                  onCheck={onCheckHandler} 
                  onContinue={onContinueHandler} 
                  onReset={onResetHandler} 
                  variant="outlined" 
                />
              : " brak kart" 
            : "not found"
        }
      </>
  );
}

export default Category;