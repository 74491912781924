import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@mui/material'
import LoderContainer from './Loader.styles';

const Loader = (props) => {
  return (<LoderContainer
    covered={props.covered}
    fixed={props.fixed}
    transparent={props.transparent}
  >
    <CircularProgress />
  </LoderContainer>);
}

Loader.propTypes = {
  covered: PropTypes.bool,
  fixed: PropTypes.bool,
  transparent: PropTypes.bool
};

Loader.defaultProps = {
  covered: false,
  fixed: false,
  transparent: false
};

export default Loader;