import { Global } from '@emotion/react';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Categories from './components/Categories/Categories';
import Category from './components/Category/Category';
import Container from './components/Container/Container';
import Toolbar from './components/Toolbar/Toolbar';
import { GlobalStyles } from './styles/global.styles';
import theme from './styles/theme';
import { FlashcardsDataProvider } from './contexts/DataContext';

function Flashcards() {
  return (
    <>
      <Global styles={GlobalStyles} />
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <Toolbar />
          
          <Container>
            <FlashcardsDataProvider>
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Categories />} />
                  <Route path="/:categoryId" element={<Category />} />
                </Routes>
              </BrowserRouter>
            </FlashcardsDataProvider>
          </Container>
        </StyledEngineProvider>
      </ThemeProvider>
    </>
  );
}

export default Flashcards;