import { Button, CardContent, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CardWrapper } from './Card.styles';

const Card = ({card, onCheck, onContinue, onReset, opened, ...props}) => (
  card ? (
    <CardWrapper {...props} >
      <CardContent sx={{ minHeight: "150px", display: "flex", alignItems: "end", justifyContent: "center", textAlign: "center" }}>
        <Typography variant="h3" component="div">{card.Polish}</Typography>
      </CardContent>
      <CardContent className={!opened ? 'invisible' : undefined} sx={{ minHeight: "150px", display: "flex", alignItems: "start", justifyContent: "center", textAlign: "center" }}>
        <Typography variant="h3" component="div" sx={{ color: "green" }}>{card.Spanish}</Typography>
      </CardContent>
      <CardContent sx={{ display: "flex", justifyContent: "center" }}>
        <Button className={opened ? 'hidden' : undefined} variant='contained' sx={{ width: "250px" }} onClick={onCheck}>Sprawdź</Button>
        <Button className={!opened ? 'hidden' : undefined} variant='contained' sx={{ width: "250px" }} onClick={onContinue}>Kontynuuj</Button>
      </CardContent>
    </CardWrapper>
  ) : (
    <CardWrapper {...props} >
      <CardContent sx={{ minHeight: "332px", display: "flex", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
        <Typography variant="h4" component="div">Nie ma więcej kart :)</Typography>
      </CardContent>
      <CardContent sx={{ display: "flex", justifyContent: "center" }}>
        <Button variant='contained' sx={{ width: "250px" }} onClick={onReset}>Zacznij od nowa</Button>
      </CardContent>
    </CardWrapper>
  )
)

Card.propTypes = {
  card: PropTypes.object,
  onCheck: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired
};

Card.defaultProps = {
};

export default Card;