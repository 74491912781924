import React, { useContext, useEffect, useState } from 'react';
import Loader from '../components/Loader/Loader'

const FlashcardsDataContext = React.createContext();
const categoryList = [];
const lookup = {};

function createCategory(categoryName)
{
  if (!categoryName) {
    return null;
  }

  var category;
  if (!(categoryName in lookup)) {
    category = {
      id: categoryList.length + 1,
      name: categoryName
    };
    lookup[categoryName] = category;
    categoryList.push(category);
  } else {
    category = lookup[categoryName];
  }

  return category;
}

function mapFlashcards(input)
{
  return input.map(f => {
    var category = createCategory(f.Category);
    return {...f, Category: category}
  });
}

export function useFlashcardsData() {
  return useContext(FlashcardsDataContext);
}

export function FlashcardsDataProvider({ children }) {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [flashcards, setFlashcards] = useState([]);

  useEffect(() => {
    async function loadFlashcards() {
      setLoading(true);
      var response = await fetch(process.env.REACT_APP_DATA_API_URL);
      var data = await response.json();
      setFlashcards(mapFlashcards(data));
      categoryList.sort((a, b) => a.name.localeCompare(b.name));
      categoryList.unshift({
        id: 0,
        name: 'Wszystkie'
      });
      setCategories(categoryList);      
      setLoading(false);
    }

    loadFlashcards();
  }, []);

  function getCategory(id) {
    return categories.find(c => c.id.toString() === id);
  }

  function getFlashcards(id) {
    return flashcards.filter(f => id === '0' || f.Category?.id.toString() === id);
  }

  const value = {
    categories,
    flashcards,
    getCategory,
    getFlashcards
  };

  return (
    <FlashcardsDataContext.Provider value={value}>
      {
        loading ? (<Loader fixed covered />) : children
      }
    </FlashcardsDataContext.Provider>
  );
}