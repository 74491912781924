import styled from '@emotion/styled';
import { css } from '@emotion/react';

const LoderContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.9); 
  ${(props) => props.covered && css`
    background: rgb(255, 255, 255);
  `}
  ${(props) => props.fixed && css`
    position: fixed;
  `}
  ${(props) => props.transparent && css`
    background: transparent;
  `}
`;

export default LoderContainer;