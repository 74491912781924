import CategoryTile from '../CategoryTile/CategoryTile';
import { CategoriesWrapper } from './Categories.styles';
import { useFlashcardsData } from '../../contexts/DataContext';

const Categories = () => {
  const {categories} = useFlashcardsData();

  return (
      <CategoriesWrapper>
        {categories.map((item) => (
          <CategoryTile key={item.id} {...item} />
        ))}
      </CategoriesWrapper>
  );
}

export default Categories;