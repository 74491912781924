import { Box } from '@mui/system';
import { Link } from 'react-router-dom';

const CategoryTile = (props) => {
    return (
        <Link to={{pathname: `/${props.id}`}}><Box 
            sx={{
                border: '1px solid',
                borderColor: (theme) => 
                  theme.palette.mode === 'dark' ? 'grey.800' : 'grey.300',
                borderRadius: '6px',
                height: '190px',
                
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '1.5rem'
            }}
        >
            {props.name}
        </Box>
        </Link>
    );
}

export default CategoryTile;