import PropTypes from 'prop-types';
import { ContainerWrapper } from "./Container.styles";

const Container = (props) => (
    <ContainerWrapper {...props} >
        {props.children}
    </ContainerWrapper>
)

Container.propTypes = {
    children: PropTypes.node
};

Container.defaultProps = {
    children: null
};

export default Container;