import styled from "@emotion/styled";
import { Card } from "@mui/material";

const CardWrapper = styled(Card)`
    &.MuiPaper-root {
        margin-top: 1.5rem;
    }
`;

export {
    CardWrapper
}