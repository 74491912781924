import React from 'react';
import { AppBar, Toolbar } from '@mui/material'
import StyleIcon from '@mui/icons-material/Style';

const ToolbarWrapper = () => (
  <AppBar position="sticky">
    <Toolbar sx={{fontSize: "2rem"}}>
      <span>Flashcards</span>
      <StyleIcon sx={{fontSize: "2rem", paddingLeft: "0.25rem"}} />
    </Toolbar>
  </AppBar>
);

export default ToolbarWrapper;