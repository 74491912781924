import styled from "@emotion/styled";
import { Container } from "@mui/system";

const ContainerWrapper = styled(Container)`
    &.MuiContainer-root {
        max-width: 1248px;
    }
`;

export {
    ContainerWrapper
}