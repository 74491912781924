import styled from '@emotion/styled';
import { Box } from '@mui/system';

const CategoriesWrapper = styled(Box)`
    &.MuiBox-root {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: ${props => props.theme.spacing(2)};
        margin-top: 1.5rem;
    }
);
`;

export {
    CategoriesWrapper
};